export const initMobileMenu = () => {
    // VARS
    const burger = document.querySelector('[data-burger]');
    const activeClass = 'mobile-menu-is-open';

    // LISTENERS
    burger?.addEventListener('click', handleOnClick);
    window.addEventListener('resize', handleOnResize);

    // HANDLERS
    function handleOnClick() {
        document.body.classList.toggle(activeClass);
    }

    function handleOnResize() {
        if (window.innerWidth >= 1024) {
            document.body.classList.remove(activeClass);
        }
    }
};
