import { initStopAnimateOnResize } from './components/initStopAnimateOnResize';
import { initMobileMenu } from './components/initMobileMenu';
import { initFunnelHash } from '../../../../Common/Resources/src_front/js/components/initFunnelHash';

document.addEventListener('DOMContentLoaded', handleOnLoadPage);

function handleOnLoadPage() {
    initFunnelHash();
    initMobileMenu();
    initStopAnimateOnResize();
}
